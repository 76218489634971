<template>
  <div class="mb-8 my-16 py-6 px-4">
    <div>redireccionando ...</div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Home.vue",
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
    }),
  },
  created(){
    if(this.info.tipoUnidad === "Académica"){
      this.$router.push("/unidades-academicas");
    }
    if(this.info.tipoUnidad === "Administrativa"){
      this.$router.push("/gobierno-central");
    }
  }
}
</script>

<style scoped>

</style>